import { classNames } from '@/components/shared/classNames'
import styles from './Title.module.scss'

export type IDecorationType = 'blue-underline' | 'dark-blue-pill'

interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  spanColor?:
    | 'none'
    | 'blue-darkest'
    | 'blue-dark'
    | 'blue-medium'
    | 'blue'
    | 'blue-light'
    | 'gray-darkest'
    | 'gray-dark'
    | 'gray'
    | 'medium'
    | 'gray-light'
    | 'gray-lightest'
    | 'purple-dark'
    | 'purple-medium'
    | 'purple'
    | 'magenta'
    | 'green'
    | 'green-dark'
    | 'white'
    | 'black'
    | 'error'
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

const TITLE_CLASSES: {
  'title-h1': string
  'title-h2': string
  'title-h3': string
  'title-h4': string
  'title-h5': string
  'title-h6': string
} = {
  'title-h1': 'title-h1',
  'title-h2': 'title-h2',
  'title-h3': 'title-h3',
  'title-h4': 'title-h4',
  'title-h5': 'title-h5',
  'title-h6': 'title-h6'
}

const Title = ({ tag = 'h1', className, spanColor = 'none', children = null, dangerouslySetInnerHTML }: Props) => {
  const Tag = tag

  return (
    <Tag
      className={classNames(
        'text-primary font-semibold',
        TITLE_CLASSES[`title-${tag}`],
        styles[`childspan-${spanColor}`],
        className
      )}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: dangerouslySetInnerHTML.__html } : undefined}
    >
      {dangerouslySetInnerHTML ? null : children}
    </Tag>
  )
}

export default Title
